<template>
  <div>
    <el-card>
      <div>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
        <el-button @click="cancel" size="small">取消</el-button>
      </div>
      <el-form ref="formRef" :model="openForm" label-width="120px" class="FormCl" :rules="rule">
        <el-form-item label="RFID：" size="small" prop="rfid_no">
          <el-input ref="cardRef" v-model="openForm.rfid_no" :disabled="openForm.rfid_no.length == 10">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="emptyChange">清空</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="样品编号：" size="small" prop="samp">
          <el-input v-model="openForm.samp" :disabled="true" placeholder="请选择样品编号">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="openSampDialog()">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="委托单号：" size="small" prop="entr">
          <el-input v-model="openForm.entr" disabled placeholder="请选择委托单号"> </el-input>
        </el-form-item>
        <el-form-item label="样品数量：" size="small" prop="smpl_num">
          <el-input v-model="openForm.smpl_num" disabled placeholder="样品数量"> </el-input>
        </el-form-item>
        <el-form-item label="所属部门：" prop="belo_dept_name" size="small" required>
          <el-input v-model="openForm.belo_dept_name" disabled placeholder="请选择所属部门"> </el-input>
        </el-form-item>
        <el-form-item label="开卡人姓名:" size="small" prop="stff_name">
          <el-input :disabled="true" v-model="openForm.stff_name"></el-input>
        </el-form-item>

        <el-form-item label="是否需要经历：">
          <el-checkbox v-model="openForm.open_status">开版</el-checkbox>
          <el-checkbox v-model="openForm.stpr_status">备料</el-checkbox>
          <el-checkbox v-model="openForm.comp_status">复合</el-checkbox>
          <el-checkbox v-model="openForm.stam_status">印花</el-checkbox>
          <el-checkbox v-model="openForm.embr_status">电绣</el-checkbox>
          <el-checkbox v-model="openForm.mase_status">机缝</el-checkbox>
          <el-checkbox v-model="openForm.lase_status">激光</el-checkbox>
          <el-checkbox v-model="openForm.handCut_status">手裁</el-checkbox>
          <el-checkbox v-model="openForm.manu_status">手工</el-checkbox>
          <el-checkbox v-model="openForm.emcd_status">制卡</el-checkbox>
          <el-checkbox v-model="openForm.insp_status" :disabled="true">检验</el-checkbox>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 样品编号 -->
    <el-dialog :visible.sync="dialogSampVisible" width="70%" title="样品信息列表(未开卡)">
      <template>
        <el-form ref="form" :model="searchSampForm" label-width="100px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="样品编号：">
                <el-input v-model="searchSampForm.smpl_no" size="small" placeholder="请输入样品编号" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="样品名称：">
                <el-input v-model="searchSampForm.smpl_name" size="small" placeholder="请输入样品名称" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属部门:">
                <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_16">
            <el-col :md="8">
              <el-button class="vg_ml_16" type="primary" size="small" @click="searchSamp">搜索</el-button>
              <el-button class="vg_mr_8" type="info" size="small" @click="searchSampO">刷新</el-button>
              <el-link type="primary" @click="gosmpl" class="vg_cursor"><i class="el-icon-edit-outline"></i>样品总控表管理</el-link>
            </el-col>
          </el-row>
          <div class="vg_mb_8"><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
          <el-table :data="SampList" border @cell-dblclick="sampDbClick" style="width: 100%" v-loading="loadingFlag" class="vg_pointer">
            <el-table-column type="index" label="序号" align="center"></el-table-column>
            <el-table-column prop="smpl_no" label="样品编号"> </el-table-column>
            <el-table-column prop="smpl_cust_no" label="客户货号">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_cust_no">{{ scope.row.smpl_cust_no }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="requ_no" label="委托编号"></el-table-column>
            <el-table-column prop="smpl_name" label="样品名称"> </el-table-column>
            <el-table-column prop="smpl_type" label="样品类型" :formatter="formatSmplType"> </el-table-column>
            <el-table-column prop="smpl_num" label="样品数量">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_num || scope.row.smpl_num === 0">{{ scope.row.smpl_num | formatSmplNum }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="smpl_deil_time"
              label="实际交样时间"
              :formatter="formatDate"
            >
            </el-table-column> -->
            <el-table-column prop="smpl_price" label="样品间报价">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_price">{{ scope.row.smpl_price | foamatSmplPrice }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="smpl_verify_price" label="采购部核价">
              <template slot-scope="scope">
                <span v-if="scope.row.smpl_verify_price">{{ scope.row.smpl_verify_price | formatSmplVerifyPrice }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="所属部门" prop="belo_dept_name"></el-table-column>
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
          </el-table>
          <pubPagination :totalPage="SampTotal" @changePageSearch="changeSampPageSearch" ref="pubPagination"></pubPagination>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl'; //getSmpls
import { rfidAPI } from '@api/modules/rfid'; //rfid
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import helper from '@assets/js/helper.js';

export default {
  name: 'openCard',
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {
    this.$refs.cardRef.focus();
  },
  created() {
    this.getDepartment();
    this.getUserInof();
    this.getSampList();
  },
  data() {
    return {
      openForm: {
        rfid_no: '',
        samp: '', //显示
        entr: '', //显示
        smpl_id: '', //样品编号id
        requ_id: '', //委托编号id

        stff_id: '', //开卡人id
        stff_name: '', //开卡人姓名
        user_id: '', //开卡人id
        stpr_status: true,
        comp_status: true,
        stam_status: true,
        embr_status: true,
        mase_status: true,
        lase_status: true,
        handCut_status: true,
        manu_status: true,
        insp_status: true,
        open_status: true,
        emcd_status: true,
        belo_dept_id: null,
        belo_dept_name: null
      },
      rule: {
        rfid_no: [{ required: true, trigger: 'blur', message: ' ' }],
        samp: [{ required: true, trigger: 'blur', message: ' ' }],
        entr: [{ required: true, trigger: 'blur', message: ' ' }],
        stff_id: [{ required: true, trigger: 'blur', message: ' ' }],
        stff_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      dialogSampVisible: false,
      SampList: [], //样品
      SampTotal: 0,
      title: ' 提示：(双击表编对应数据即可选中人员)',
      searchSampForm: {
        page_no: 1,
        smpl_no: null,
        smpl_name: null,
        belo_dept_id: null,
        isDyj: true,
        beloDeptId: null
      },
      loadingFlag: true,
      selectRefuDisabled: false
    };
  },
  filters: {
    foamatSmplPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplVerifyPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplNum(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    //获取打样部门
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchSampForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchSampForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    //获取样品数据
    getSampList() {
      get(smplAPI.get_all_smpls_v1, this.searchSampForm)
        .then(res => {
          if (res.data.code !== 0) {
            return console.log('数据请求失败');
          }
          this.SampList = res.data.data.list;
          this.SampTotal = res.data.data.total;
          setTimeout(() => {
            this.loadingFlag = false;
          }, 300);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    //从cookie中获取信息
    getUserInof() {
      let info = this.$cookies.get('userInfo');
      this.openForm.stff_id = info.stff_id;
      this.openForm.stff_name = info.stff_name;
      this.openForm.user_id = info.user_id;
    },
    //选择 样品编号
    openSampDialog() {
      this.dialogSampVisible = true;
    },
    openEntrDialog() {
      this.dialogEntrVisible = true;
    },
    // 样品类型判断
    formatSmplType(row) {
      if (row.smpl_type === 1) {
        return '新款';
      } else if (row.smpl_type === 2) {
        return '老款';
      } else if (row.smpl_type === 3) {
        return '修改款';
      }
    },
    //样品 双击获取数据
    sampDbClick(val) {
      this.openForm.smpl_id = val.smpl_id;
      this.openForm.samp = val.smpl_no;
      this.openForm.entr = val.requ_no;
      this.openForm.smpl_num = val.smpl_num;
      this.openForm.requ_id = val.requ_id;
      this.openForm.belo_dept_id = val.belo_dept_id;
      this.openForm.belo_dept_name = val.belo_dept_name;

      this.dialogSampVisible = false;
    },
    changeSampPageSearch(val) {
      this.searchSampForm.page_no = val;
      this.getSampList();
    },
    searchSamp() {
      this.loadingFlag = true;
      this.searchSampForm.page_no = 1;
      this.getSampList();
    },
    // 刷新
    searchSampO() {
      this.loadingFlag = true;
      this.searchSampForm.page_no = 1;
      (this.searchSampForm.smpl_no = null), (this.searchSampForm.smpl_name = null);
      if (this.isDyj) {
        this.searchSampForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchSampForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.getSampList();
    },
    submit() {
      const form = {};
      for (let i in this.openForm) {
        form[i] = this.openForm[i];
      }
      form.lase_status = this.openForm.lase_status - 0;
      form.handCut_status = this.openForm.handCut_status - 0;
      form.stpr_status = this.openForm.stpr_status - 0;
      form.comp_status = this.openForm.comp_status - 0;
      form.embr_status = this.openForm.embr_status - 0;
      form.mase_status = this.openForm.mase_status - 0;
      form.manu_status = this.openForm.manu_status - 0;
      form.insp_status = this.openForm.insp_status - 0;
      form.stam_status = this.openForm.stam_status - 0;
      form.open_status = this.openForm.open_status - 0;
      form.emcd_status = this.openForm.emcd_status - 0;

      if (!this.openForm.rfid_no) {
        return this.$message.warning('Rfid不可为空');
      }
      if (!this.openForm.smpl_id) {
        return this.$message.warning('样品编号不可为空');
      }
      if (!this.openForm.requ_id) {
        return this.$message.warning('委托编号不可为空');
      }
      this.$confirm('是否开卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

        .then(() => {
          post(rfidAPI.open_card, form).then(res => {
            if (res.data.code !== 0) {
              return this.$message.error(res.data.msg);
            }
            this.$message.success(res.data.msg);
            this.jump(`/open_edit?rfid_id=${res.data.data.form_id}`);
            this.$refs.formRef.resetFields();
          });
        })
        .catch(() => {});
    },
    cancel() {
      this.$confirm('取消开卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.jump('open_list');
        })
        .catch(() => {});
    },
    emptyChange() {
      this.openForm.rfid_no = '';
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    // 时间转换
    formatDate(row) {
      if (row.smpl_deil_time === 0) {
        return '暂无';
      }
      return this.helper.toTimeDay(row.smpl_deil_time);
    },
    //跳入样品总控
    gosmpl() {
      this.jump(`smpl_list?perm_id=${this.helper.retPermId('smpl')}`);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchSampForm.belo_dept_id = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}
.FormCl {
  width: 70%;
  margin: 0 auto;
}
</style>
